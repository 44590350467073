<template>
    <div>
        <SectionIntro />
        <SectionSpam />
        <SectionExcitingOffers />
        <SectionMatches />
        <SectionFOMO />
        <SectionC2A />
    </div>
</template>

<script>
import { ROUTE } from '@/enums'
import {
    SectionIntro,
    SectionSpam,
    SectionExcitingOffers,
    SectionMatches,
    SectionFOMO,
    SectionC2A,
} from './components'

export default {
    name: 'LandingAntiSpan',

    components: {
        SectionIntro,
        SectionSpam,
        SectionExcitingOffers,
        SectionMatches,
        SectionFOMO,
        SectionC2A,
    },

    data: () => ({ ROUTE }),
}
</script>
